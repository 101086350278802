.card-button{
    width: 100%;
    height: 350px;
    border: 1px solid white;
    overflow: hidden;
    border-radius: 5px; /* Bordi leggermente arrotondati */
    /* box-shadow: 0px -2px 5px rgba(255, 255, 255, 0.481);  */
    border: none; /* Nessun bordo */
    cursor: pointer; /* Cursor mano */
    
    /* background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px); */
    color: white;
    background: rgba(255, 255, 255, 0.21);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7.1px);
    -webkit-backdrop-filter: blur(7.1px);
    border: 1px solid rgba(255, 255, 255, 0.48);
    

}
.card-container{
    width: 345px;
    height: 100%;
    padding: 10px;
    background-color: #d9d9d962;
    opacity: 0.9;
    flex-direction: column;
    margin-top: 20px;
    justify-content:space-around;
    border-radius: 10px;
    
}

.bottomContainer{
    
    width: 100%;
    color:white;
    opacity: 0.7;
    font-size: 44px;
    line-height: 104px;
    text-transform: uppercase;
    text-decoration:wavy;
    font-weight: bold;
text-align: center;
}


.smallText{
    font-size: 15px;
}

.card-container-big{
    width: 100%;
    height: 750px;
    padding: 10px;
    background-color: #d9d9d962;
    opacity: 0.9;
    flex-direction: column;
    margin-top: 20px;
    justify-content:space-around;
    border-radius: 10px;
    
}