
.wrapper-intro-page{
    width: 100%;
    height: 100vh;
position: absolute;
    background-color: blue;
    overscroll-behavior-y: contain;
}

.wrapper-selection-page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 24;

}


.cloudy-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 24;
  background: radial-gradient(#d6e1e8, #d7c8d5);
  -webkit-overflow-Y: hidden;
  -moz-overflow-Y: hidden;
  -o-overflow-Y: hidden;
  overflow-y: hidden;
  -webkit-animation: fadeIn 1 1s ease-out;
  -moz-animation: fadeIn 1 1s ease-out;
  -o-animation: fadeIn 1 1s ease-out;
  animation: fadeIn 1 1s ease-out;
  perspective: 23vmin;
  backdrop-filter: blur(35px);
  overflow-y: scroll;
}


.cloudy-background-blur {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(30px);
  /* Regola il valore di sfocatura come desiderato */
  z-index: 25;
  /* Deve essere maggiore di cloudy-background per sovrapporsi */
}

.light {
  position: absolute;
  width: 0px;
  opacity: .75;
  background-color: rgb(97, 166, 211);
  box-shadow: #6f88ad 0px 0px 20px 2px;
  opacity: 0;
  top: 100vh;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
}

.x1 {
  -webkit-animation: floatUp 40s infinite linear;
  -moz-animation: floatUp 40s infinite linear;
  -o-animation: floatUp 40s infinite linear;
  animation: floatUp 40s infinite linear;
  -webkit-transform: scale(1.0);
  -moz-transform: scale(1.0);
  -o-transform: scale(1.0);
  transform: scale(1.0);
}

.x2 {
  -webkit-animation: floatUp 70s infinite linear;
  -moz-animation: floatUp 70s infinite linear;
  -o-animation: floatUp 70s infinite linear;
  animation: floatUp 70s infinite linear;
  -webkit-transform: scale(1.6);
  -moz-transform: scale(1.6);
  -o-transform: scale(1.6);
  transform: scale(1.6);
  left: 15%;
}

.x3 {
  -webkit-animation: floatUp 20.5s infinite linear;
  -moz-animation: floatUp 20.5s infinite linear;
  -o-animation: floatUp 20.5s infinite linear;
  animation: floatUp 20.5s infinite linear;
  -webkit-transform: scale(.5);
  -moz-transform: scale(.5);
  -o-transform: scale(.5);
  transform: scale(.5);
  left: -15%;
}

.x4 {
  -webkit-animation: floatUp 14.5s infinite linear;
  -moz-animation: floatUp 14.5s infinite linear;
  -o-animation: floatUp 14.5s infinite linear;
  animation: floatUp 14.5s infinite linear;
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
  left: -34%;
}

.x5 {
  -webkit-animation: floatUp 18s infinite linear;
  -moz-animation: floatUp 18s infinite linear;
  -o-animation: floatUp 18s infinite linear;
  animation: floatUp 18s infinite linear;
  -webkit-transform: scale(2.2);
  -moz-transform: scale(2.2);
  -o-transform: scale(2.2);
  transform: scale(2.2);
  left: -57%;
}

.x6 {
  -webkit-animation: floatUp 13s infinite linear;
  -moz-animation: floatUp 13s infinite linear;
  -o-animation: floatUp 13s infinite linear;
  animation: floatUp 13s infinite linear;
  -webkit-transform: scale(.8);
  -moz-transform: scale(.8);
  -o-transform: scale(.8);
  transform: scale(.8);
  left: -81%;
}

.x7 {
  -webkit-animation: floatUp 15.3s infinite linear;
  -moz-animation: floatUp 15.3s infinite linear;
  -o-animation: floatUp 15.3s infinite linear;
  animation: floatUp 15.3s infinite linear;
  -webkit-transform: scale(3.2);
  -moz-transform: scale(3.2);
  -o-transform: scale(3.2);
  transform: scale(3.2);
  left: 37%;
}

.x8 {
  -webkit-animation: floatUp 4.7s infinite linear;
  -moz-animation: floatUp 4.7s infinite linear;
  -o-animation: floatUp 4.7s infinite linear;
  animation: floatUp 4.7s infinite linear;
  -webkit-transform: scale(1.7);
  -moz-transform: scale(1.7);
  -o-transform: scale(1.7);
  transform: scale(1.7);
  left: 62%;
}

.x9 {
  -webkit-animation: floatUp 4.1s infinite linear;
  -moz-animation: floatUp 4.1s infinite linear;
  -o-animation: floatUp 4.1s infinite linear;
  animation: floatUp 4.1s infinite linear;
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -o-transform: scale(0.9);
  transform: scale(0.9);
  left: 85%;
}

button:focus {
  outline: none;
}



@-webkit-keyframes floatUp {
  0% {
    top: 100vh;
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  50% {
    top: 0vh;
    opacity: .8;
  }

  75% {
    opacity: 1;
  }

  100% {
    top: -100vh;
    opacity: 0;
  }
}

@-moz-keyframes floatUp {
  0% {
    top: 100vh;
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  50% {
    top: 0vh;
    opacity: .8;
  }

  75% {
    opacity: 1;
  }

  100% {
    top: -100vh;
    opacity: 0;
  }
}

@-o-keyframes floatUp {
  0% {
    top: 100vh;
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  50% {
    top: 0vh;
    opacity: .8;
  }

  75% {
    opacity: 1;
  }

  100% {
    top: -100vh;
    opacity: 0;
  }
}

@keyframes floatUp {
  0% {
    top: 100vh;
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  50% {
    top: 0vh;
    opacity: .8;
  }

  75% {
    opacity: 1;
  }

  100% {
    top: -100vh;
    opacity: 0;
  }
}


@-webkit-keyframes floatDiagonal {
  0% {
    top: 100vh;
    left: -100vw;
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  50% {
    top: 0;
    left: 100vw;
    opacity: 0.8;
  }

  75% {
    opacity: 1;
  }

  100% {
    top: -100vh;
    left: 200vw;
    opacity: 0;
  }
}

@-moz-keyframes floatDiagonal {
  0% {
    top: 100vh;
    left: -100vw;
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  50% {
    top: 0;
    left: 100vw;
    opacity: 0.8;
  }

  75% {
    opacity: 1;
  }

  100% {
    top: -100vh;
    left: 200vw;
    opacity: 0;
  }
}

@-o-keyframes floatDiagonal {
  0% {
    top: 100vh;
    left: -100vw;
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  50% {
    top: 0;
    left: 100vw;
    opacity: 0.8;
  }

  75% {
    opacity: 1;
  }

  100% {
    top: -100vh;
    left: 200vw;
    opacity: 0;
  }
}

@keyframes floatDiagonal {
  0% {
    top: 100vh;
    left: -100vw;
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  50% {
    top: 0;
    left: 100vw;
    opacity: 0.8;
  }

  75% {
    opacity: 1;
  }

  100% {
    top: -100vh;
    left: 200vw;
    opacity: 0;
  }
}


:root {
  --color-bg1: #6c00a2;
  --color-bg2: #001152;
  --color1: 18, 113, 255;
  --color2: 221, 74, 255;
  --color3: 100, 220, 255;
  --color4: 200, 50, 50;
  --color5: 180, 180, 50;
  --color-interactive: 140, 100, 255;
  --circle-size: 80%;
  --blending: hard-light;
}

@keyframes moveInCircle {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes moveVertical {
  0% {
    transform: translateY(-50%);
  }

  50% {
    transform: translateY(50%);
  }

  100% {
    transform: translateY(-50%);
  }
}

@keyframes moveHorizontal {
  0% {
    transform: translateX(-50%) translateY(-10%);
  }

  50% {
    transform: translateX(50%) translateY(10%);
  }

  100% {
    transform: translateX(-50%) translateY(-10%);
  }
}


.scrool-container {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  
}

.scrool-container::-webkit-scrollbar {
display: none !important;
}

.header{
  height: 180px;
  width: 100%;
  padding: 10px 10px;
  margin-bottom: 100px;
  justify-content: center;
  align-items: center;
  align-content: center;
  align-self: center;
  display: flex;
}

  :root {
    --color-bg1: #6c00a2;
    --color-bg2: #001152;
    --color1: 18, 113, 255;
    --color2: 221, 74, 255;
    --color3: 100, 220, 255;
    --color4: 200, 50, 50;
    --color5: 180, 180, 50;
    --color-interactive: 140, 100, 255;
    --circle-size: 80%;
    --blending: hard-light;
  }
  @keyframes moveInCircle {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes moveVertical {
    0% {
      transform: translateY(-50%);
    }
    50% {
      transform: translateY(50%);
    }
    100% {
      transform: translateY(-50%);
    }
  }
  @keyframes moveHorizontal {
    0% {
      transform: translateX(-50%) translateY(-10%);
    }
    50% {
      transform: translateX(50%) translateY(10%);
    }
    100% {
      transform: translateX(-50%) translateY(-10%);
    }
  }
 

  .scrool-container{
    width: 100%;
    height: 100%;
    overflow-y: scroll;
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .transition-screen {
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    animation: fadeIn 0.5s ease forwards;
  }
  
  .hide-transition {
    animation: fadeOut 1s ease forwards;
  }
  