body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.rc-slider-vertical .rc-slider-track{
  width: 10px !important;
}
.rc-slider-vertical .rc-slider-rail {
  width: 10px !important;
}

.rc-slider-vertical .rc-slider-dot{
  margin-left: -5px !important;
}

.rc-slider-vertical .rc-slider-handle{
  margin-left: -15px !important;
}

.rc-slider-vertical .rc-slider-handle{
  width: 10px !important;
}

.rc-slider-rail{
  background-color: white !important;
}

.rc-slider-dot{
  border: none !important;
}

.rc-slider-dot-active{
    background-color: rgb(66, 177, 172) !important;
}

.rc-slider-vertical .rc-slider-handle {
  width: 40px !important;
  background-color:rgb(66, 177, 172) !important ;
}


* {
  -webkit-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none; 
  user-select: none; 
} 

/* video{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
} */


.regular-space{
  margin-top: 80px;
}

.mvp-logo-homepage{
  width: 60%;
  margin-top: -80px;
  margin-bottom: 80px;
}

.space{
  margin-bottom: 180px;
}


.root-selector-header{
  width: 98%;
  height: 10vh;
  background-color: white;
  position: absolute;
  margin-left: 1%;
  top:0px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  /* background-image: url("https://firebasestorage.googleapis.com/v0/b/arshades-7e18a.appspot.com/o/FCMImages%2Fphoto_2024-05-12_21-40-23.jpg?alt=media&token=da35715c-9dcd-4d83-8e59-d8a9cd416e6c");
  background-size: cover; */
  /* background-position: top; */
  font-size: 64px;
  font-weight: bold;
  line-height: 10vh;
  text-align: center;
  color: white;
  display: flex;
    justify-content: center; /* Allinea orizzontalmente al centro */
    align-items: center;
}

.logo-header-root-img{
  width: 60%;
padding: 10px;
}
.flex-container-button{
  display: flex; /* Imposta il display a flex */
  flex-direction: row; /* Imposta la direzione dei figli a riga (orizzontale) */
  justify-content: center; /* Allinea i figli al centro lungo l'asse principale (orizzontalmente) */
  align-items: center; /* Allinea i figli al centro lungo l'asse trasversale (verticalmente) */
  width: 100%; /* Larghezza del container */
}

.experience-container{
  position: absolute;
  height: 80vh;
  width: 96%;
  background-color: white;
  margin-left: 2%;
  border-radius: 50px;
  top: 11vh;
  left: 0;
  z-index: 999;
}

.esc-button-experience{
  font-size: 64px;
  color: black;
  text-align: right;
  margin-right: 40px;
  padding: 20px;
}

.experience-iframe{
  width: 100%;
  height: 80%;
  border: none;
}



@media (max-width: 1366px) {

.logo-header-root-img{
width: 40%;
/* padding: 40px; */
/* margin-top: 20%; */
}

}