.modal {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background-color: white;
  text-align: center;
  width: 80%;
  height: 80%;
  z-index: 999;
  position: fixed;
  top: 10%;
  left: calc(50% - 40%);
  display: block !important;
  overflow-y: auto;
}

.modal-dialog {
  overflow-y: initial !important;
}

.modal-body {
  padding: 0px;
}
/*loading bar */
/* 
 ::-webkit-scrollbar {
  width: 5px;
} 

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #eb505d;
  border-radius: 10px;
}  */
/**/
.modal-small {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background-color: white;
  width: 230px;
  height: 310px;
  z-index: 999;
  position: fixed;
  display: block !important;
  overflow-y: auto;
  right: 75px;
  top: 20px;
}

.modal-small-bottom {
  box-shadow: 0 1px 4px rgb(0 0 0 / 20%);
  border-radius: 4px;
  background-color: white;
  width: 230px;
  height: 310px;
  z-index: 999;
  position: fixed;
  display: block !important;
  overflow-y: auto;
  /* right: 75px; */
  bottom: calc(50% - 155px);
  right: calc(50% - 115px);
}

@media screen and (max-width: 992px) {
  .modal {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    background-color: white;
    text-align: center;
    width: 80%;
    height: 80%;
    z-index: 999;
    position: fixed;
    top: 10%;
    left: calc(50% - 40%);
    display: block !important;
    overflow-y: auto;
  }
}

.model-container {
  margin: 0 auto;
  display: absolute;
  width: 100%;
  min-height: 100%;
  padding: 0;
  margin: 0;
  background-size: 880px;
  background-position-y: 200px;
  background-position-x: center;
  background-repeat: no-repeat;
}

.img-poster {
  margin-top: auto;
  height: auto;
  width: 100%;
  text-align: center;
  object-fit: cover;
}

.div-poster {
  text-align: center;
  margin-top: 50px;
  padding: 20px;
}

.loader {
  border: 26px solid #ffffff80; /* Light grey */
  border-top: 26px solid #fe5d60; /* Blue */
  border-radius: 50%;
  width: 460px;
  height: 460px;
  animation: spin 2s linear infinite;
  margin: 0 auto;
  margin-top: 20px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-message {
  font-size: 28px;
  padding: 5px;
  background-color: #fe5d60;
  color: white;
  border-radius: 20px;
  text-align: center;
  margin-top: 80px;
}

.annotation {
  background-color: #f2f2f2;
  position: absolute;
  transform: translate(-150px, -150px);
  border-radius: 10px;
  padding: 10px;
  width: 300px;
  text-align: left;
  color: #141212;
  box-shadow: 1px 1px 5px BLACK;
  z-index: 1999;
}

@media screen and (max-width: 992px) {
  .model-container {
    margin: 0 auto;
    display: absolute;
    width: 100%;
    min-height: 100%;
    padding: 0;
    margin: 0;
    background-size: 380px;
    background-position-x: center;
    background-position-y: 200px;
    background-repeat: no-repeat;
  }
  .annotation {
    background-color: #f2f2f2;
    position: absolute;
    transform: translate(-150px, -150px);
    border-radius: 10px;
    padding: 10px;
    width: 250px;
    text-align: left;
    color: #141212;
    z-index: 1999;
  }

  .loader {
    border: 26px solid #ffffff80; /* Light grey */
    border-top: 26px solid #fe5d60; /* Blue */
    border-radius: 50%;
    width: 200px;
    height: 200px;
    animation: spin 2s linear infinite;
    margin: 0 auto;
    margin-top: 80px;
  }

  .loading-message {
    font-size: 18px;
    padding: 5px;
    background-color: #fe5d60;
    color: white;
    border-radius: 20px;
    text-align: center;
    margin: 0 auto;
    margin-top: 80px;
  }

  .paragrafo-modale-2 {
    font-size: 12px;
  }

  .hotspot-text-style {
    font-size: 12px;
  }
}

/* model-viewer {
    height: 100%;
    width: 100%;
  } */

.button-container-ar {
  position: fixed;
  right: 20;
  top: 20;
}

.ar-button {
  color: #ffffff00;
  width: 50px;
  height: 50px;
  font-family: Georgia;
  border: none;
  padding: 0px;
  position: fixed;
  right: 20px;
  top: 110px;
  z-index: 997;
}

button {
  background-color: #ffffff00;
}

.ar-button img:hover {
  text-decoration: none;
  border-radius: 10px;
}

.ar-button img {
  color: #141212;
  border: #141212;
  border-radius: 10px;
  width: 40px;
  padding: 3px;
}

.container-model-ar {
  text-align: center;
  /* padding-right: 10px; */
  padding-bottom: 0;
}

.qr-code {
  width: 230px;
  padding: 0px;
}

.text-modal-view {
  margin: 0;
  font-size: 14px;
  padding: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  color: black;
}

.text-modal-view span {
  margin: 0;
  font-size: 18px;
  padding: 0px;
  font-weight: 800;
}

.by-spaarkly {
  margin: 0;
  font-size: 10px;
  line-height: 5px;
  padding: 0px;
  padding-bottom: 10px;
  padding-right: 10px;
  color: lightslategrey;
}

.by-spaarkly a {
  text-decoration: none;
  margin: 0;
  font-size: 10px;
  line-height: 5px;
  padding: 0px;
  color: lightslategrey;
}

.hotspot {
  width: 35px;
  height: 35px;
  border-radius: 50px;
  border: none;
  background-color: #fe5d60;
  text-align: center;
  color: #fff;
  font-size: 16px;
}

.hide-annotation {
  display: none;
}

.modal-title {
  color: #fe5d60;
  font-size: 24px;
  padding: 10px;
  padding-bottom: 10px;
}

.modal-sub-title {
  color: #fe5d60;
  font-size: 20px;
  padding: 10px;
  padding-bottom: 5px;
}

.paragrafo-modale {
  font-size: 16px;
}

.hide-viewer {
  display: none;
}

.container-qr {
  /* display: flex; */
  /* align-items: center; */
  justify-content: center;
  /* width: 100%; */
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
