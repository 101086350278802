.header-container{
  flex-direction:column;
  color:white;
  z-index:99999999;
  position: relative;
  left: '100%';

  
}

.title {
  font-size: 60px;
  font-weight: bold;
  text-align: center;
  color:white;
  margin-bottom: 25px;
}
.subtitle {
  font-size: 48px;
  font-weight: 700px;
  text-align: center;
  color:white;
  margin-bottom: 20px;
}
.subtitle2 {
  font-size: 36px;
  font-weight: 700px;
  text-decoration: underline;
  text-align: center;
  color:white;
  margin-bottom: 30px;
  border: none;
  justify-content: center;
  align-items: center;
  align-self: center;
  display: flex;
  flex-direction: row;
}
.skipBtn {
  font-size: 28px;
  border: none;
  color: white;
}