.mirror-button {
    display: flex;
    align-items: center; /* Allinea verticalmente */
    justify-content: center; /* Centra orizzontalmente */
    width: 73%; /* Larghezza rispetto al contenitore padre */
    height: 150px; /* Altezza del bottone */
    border-radius: 5px; /* Bordi leggermente arrotondati */
    border: none; /* Nessun bordo */
    padding: 0 10px; /* Padding interno */
    cursor: pointer; /* Cursor mano */
    margin: 20px;
    border: 0px solid black;
    box-sizing: border-box;   
    list-style: none;
    text-decoration: none;
    z-index: 0;
    color: white;
    background: rgba(255, 255, 255, 0.21);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7.1px);
    -webkit-backdrop-filter: blur(7.1px);
    border: 1px solid rgba(255, 255, 255, 0.48);
}

.mirror-button-box {
  display: flex;
  align-items: center; /* Allinea verticalmente */
  justify-content: center; /* Centra orizzontalmente */
  width: 35%; /* Larghezza rispetto al contenitore padre */
  height: 300px; /* Altezza del bottone */
  border-radius: 5px; /* Bordi leggermente arrotondati */
  border: none; /* Nessun bordo */
  padding: 0 20px; /* Padding interno */
  cursor: pointer; /* Cursor mano */
  margin: 20px;
  border: 0px solid black;
  box-sizing: border-box;   
  list-style: none;
  text-decoration: none;
  z-index: 0;
  color: white;
  background: rgba(255, 255, 255, 0.21);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.1px);
  -webkit-backdrop-filter: blur(7.1px);
  border: 1px solid rgba(255, 255, 255, 0.48);
}

.container{
  display: flex;
  align-items: center; /* Allinea verticalmente */
  justify-content: center; /* Centra orizzontalmente */
  width: 43%; /* Larghezza rispetto al contenitore padre */
  height: 100px; /* Altezza del bottone */
  padding: 0 10px; /* Padding interno */
  cursor: pointer; /* Cursor mano */
  margin: 20px;
  border: none; /* Nessun bordo */
  border-radius: 5px; /* Bordi leggermente arrotondati */
}
  
  .button-icon {
    height: 40px; /* Altezza dell'icona */
    margin-right: 30px; /* Margin tra icona e testo */
  }
  
  /* Nuovo stile per il testo */
  .button-text {
    display: inline-block; /* Display inline per il testo */
    vertical-align: middle; /* Allineamento verticale */
    font-size: 45px;
    font-weight: 400;
    text-transform: uppercase;
  }
  
  