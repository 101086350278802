.circular_container{
  width: 175px;
  height: 175px;
  border-radius: 100px;
  border: 1px solid white;
  z-index: 12;
}

.variation1{
  background-color: #92EBE5;
  /* position: absolute; */
  /* top:330px;
  left: 150px; */
  z-index: 12;
  width: 100px;
  height: 100px;
}
/* .textV1{
  
  position: absolute;
  top:190px;
  left: 10px;
  z-index: 12;

} */
.variation2{
  background-color: #B0D26E;
  /* position: absolute; */
  /* top:400px; */
  /* animation: shake 0.8s ease-in-out 6; */
  /* left: 50%;  */
  /* transform: translateX(-50%);  */
  z-index: 12;
  width: 100px;
  height: 100px;
}
/* .textV2{
  
  position: absolute;
  top:310px;
  animation: shake 0.8s ease-in-out 6;
  left: 380px;
} */
.variation3{
  background-color: #9E81F1;
  /* position: absolute;
  top:390px;
  right: 150px; */
  z-index: 12;

  width: 100px;
  height: 100px;
}
/* .textV3{
  position: absolute;
  top:290px;
  right: 100px;
} */

.btnImage{
  width: 75px;
  height: 75px;
  margin-left: 13px;
  margin-top: 13px;
}

/* .title {
  color: white;
  margin-top: 50px;
  width: 200px;
  text-align: center;
  justify-content: center;
  display: flex;


  
} */




.title {
  position: absolute;
  /* bottom: -50px; Aggiusta la distanza dal fondo del cerchio */
  left: 50%;
  margin-top: 30px;
  transform: translateX(-50%);
  color: white;
  width: 200px; /* O la larghezza necessaria */
  text-align: center;
  text-transform: uppercase;
  font-size: 24px;
}

/* Altre classi rimangono invariate */

/* 
@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  25%, 75% {
    transform: translateX(-15px);
  }
  50% {
    transform: translateX(15px);
  }
} */

.container{
  display: flex;
}