/* :root {
  --color-bg1: rgba(210, 210, 210, 0.9); 
  --color-bg2: rgba(180, 180, 180, 0.9); 
  --color1: 255, 255, 255; 
  --color2: 240, 240, 240; 
  --color3: 200, 200, 200; 
  --color4: 160, 160, 160; 
  --color5: 120, 120, 120; 
  --color-interactive: 255, 250, 205; 
  --circle-size: 70%;
  --blending: overlay; 
  } */

  :root {
    --color-bg1: rgba(173, 216, 230, 0.9); 
    --color-bg2: rgba(135, 206, 235, 0.9); 
    --color1: 202, 249, 255; 
    --color2: 176, 224, 230;
    --color3: 100, 149, 237; 
    --color4: 70, 130, 180; 
    --color5: 30, 144, 255; 
    --color-interactive: 175, 238, 238; 
    --circle-size: 70%;
    --blending: overlay;
  }

  /* :root {
    --color-bg1: rgba(139, 0, 0, 0.9);
    --color-bg2: rgba(178, 34, 34, 0.9);
    --color1: 220, 20, 60;
    --color2: 255, 69, 0;
    --color3: 205, 92, 92;
    --color4: 255, 0, 0;
    --color5: 255, 99, 71;
    --color-interactive: 255, 160, 122;
    --circle-size: 70%;
    --blending: overlay;
  }
   */
  /* :root {
    --color-bg1: rgba(30, 30, 30, 0.9);
    --color-bg2: rgba(45, 45, 45, 0.9);
    --color1: 70, 70, 70;
    --color2: 90, 90, 90;
    --color3: 110, 110, 110;
    --color4: 130, 130, 130;
    --color5: 150, 150, 150;
    --color-interactive: 200, 200, 200;
    --circle-size: 70%;
    --blending: overlay;
  }
   */

  .loading-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centra gli elementi figli */
    justify-content: center;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #f0f0f0; /* Colore di sfondo opzionale */
  }
  
  .progress-bar-container {
    width: 60%; /* Larghezza del contenitore della barra di caricamento */
    height: 30px; /* Altezza del contenitore della barra di caricamento */
    background-color: #ddd; /* Colore di sfondo del contenitore della barra di caricamento */
    border-radius: 15px; /* Bordo arrotondato */
    border: 1px solid #ccc; /* Bordo sottile */
    overflow: hidden; /* Assicura che la barra interna non fuoriesca dai bordi arrotondati */
  }
  
  .progress-bar {
    height: 100%; /* Altezza della barra di caricamento */
    background: linear-gradient(40deg, var(--color-bg1), var(--color-bg2)); /* Gradiente */
    border-radius: 15px; /* Bordo arrotondato */
    transition: width 0.5s ease; /* Animazione fluida */
    animation: moveGradient 2s ease infinite; /* Animazione del gradiente */
  }
  
  @keyframes moveGradient {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }
  

  .loading-title {
    text-align: center;
    margin-bottom: 20px; /* Distanza dal titolo alla barra di caricamento */
    /* Altri stili desiderati per il titolo, come colore, font, ecc. */
  }