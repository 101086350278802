
@import './variables.css';

.logo_iamge_policy{
  width:10%;
}

.logo_policy_arshades{
  margin-top: 60px;
  height: 20%;
}

.title{
  /* height: 6%; */
}

.pop-up-qr-code{
  right: 20px;
  position: absolute;
  top: 20px;
  width: 35px;
  height: 35px;
  z-index: 8;
  border-radius: 200px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pop-up-qr-code-iframe{
  left: 20px;
  position: absolute;
  top: 20px;
  width: 35px;
  height: 35px;
  z-index: 8;
  border-radius: 200px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.effect-button{
  z-index: 6;
  position: absolute;
  background-color: white;
  border: none;
  border-radius: 5px;
  text-transform: uppercase;
  left: 0;
  right: 0;
  margin: auto;
  width: 200px;
  margin-top: 20px;
  background-color: var(--primary-color);
  color: white;
  height: 25px;
  font-weight: 800;
}

.subtitle-policy{
  /* height: 4%; */
  padding: 20px;
  color: var(--font-color)
}

.menu_vto{

}

.title_brand{
  color: #888888;
  font-weight: 400;
  /* font-weight: bold; */
  /* padding-top: 8px; */
  /* align-self: center; */
}

.menu_header{
  width: 100%;
  height: 25%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* justify-content: space-between; */
}

.title_glasses_container {
  flex: 1 1;
  display: flex;
  justify-content: center;
  position: absolute;
  margin: 0 auto;
  width: 100%;
}

.title_glasses {
  text-align: center;
  flex-grow: 1; /* Questo farà in modo che il titolo si espanda e venga centrato */
}
.size_selector_container {
  z-index: 5;
  /* justify-content: space-between; /* spazio tra gli elementi */
  /* align-items: flex-end; allineamento verticale alla fine */
} 
/* .container .element:first-child {
  max-width: 30%;
  justify-content: flex-start; /* allineamento orizzontale a sinistra
}

.container .element:nth-child(2) {
  justify-content: center; /* allineamento orizzontale al centro 
}  */

  .select_size_title{
    margin-right: 10px;
    font-size: 22px;
    padding: 5px;
    color: black;
    line-height: 50px;
    font-weight: bold;
    margin-left: 20px;
}

.item-dot-size {
  border-radius: 50px; /* Rende il div un cerchio */
  background-color: var(--primary-color); /* Colore di sfondo */
  width: 40px; /* Larghezza del cerchio */
  height: 40px; /* Altezza del cerchio */
  color: white; /* Colore del testo */
  font-size: 20px; /* Dimensione del testo */
  font-weight: 600; /* Peso del font */
  border: 1px solid #000; /* Bordo del cerchio */

  display: flex; /* Usa Flexbox */
  justify-content: center; /* Centra orizzontalmente */
  align-items: center; /* Centra verticalmente */
  text-align: center; /* Assicura che il testo sia centrato */
}


.loading-lottie{
  width: 100px;
}

.item-dot-size-unselected{
  border-radius: 50px;
  background-color:#ffffff ;
  border-color: var(--primary-color);
  border: 1px solid;
  width: 50px;
  height: 50px;
  /* margin-top: 10px; */
  color: #000;
  font-size: 20px;
  font-weight: 600;

  display: flex; /* Usa Flexbox */
  justify-content: center; /* Centra orizzontalmente */
  align-items: center; /* Centra verticalmente */
  text-align: center; /* Assicura che il testo sia centrato */
}

.trash-container{
  margin-top: -5px;
  position: absolute;
  left: -25px;
  border-radius: 50px;
  /* background-color: #fff; */
  width: 20px;
  height: 20px;
  /* padding: 5px;*/
}

.icon-trash-photo{
  height: 16px;
  color: #fff;
}

.select_size{
  grid-row: 1;
  grid-column: 1;
  /* position: absolute; */
  left: 10px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
}  

.title_glasses{
  color: #302f2f;
  font-weight: bold;
  padding-top: 10px;
  /* align-self: center; */
  text-align: center;
  flex-grow: 1; /* Questo farà in modo che il titolo si espanda e venga centrato */
}

.item-dot-size-unselected + .item-dot-size{
  margin-left: 5px;
}
.item-dot-size +.item-dot-size-unselected {
  margin-left: 5px;

}

.item-dot-size-unselected + .item-dot-size-unselected{
  margin-left: 5px;
}

.item-dot-size + .item-dot-size{
  margin-left: 5px;
}

.menu_container{
    background-color: #ffffff;
    width: 100%;
    height: 75%;
    position: absolute;
    /* align-items: center;
    justify-content: center; */
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
      z-index: 2;
    align-items: center;
    padding-top: 10px;
    }
    
    .menu_container-desk{
      background-color: #ffffff;
      width: 100%;
      height: 75%;
      position: absolute;
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
        z-index: 2;
        padding-left: 20px;
    padding-right: 20px;
    }
    
    .output_canvas{
      /* overflow: hidden; */
      /* margin: 0 auto; 
      position: absolute;
   left: 50%;
  top: calc(50% - 10%);
  transform: translate(-50%, -50%); */
    }
    
    .variant_card{
      width: 270px;
  border: 0;
  /* flex-basis: 33.333%; */
  /* margin-top: 5px; */
  /* bottom: 10; */
  text-align: center;
  background-color: #fff;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  /* margin: 15px; */
    }

    .variant_card + .variant_card {
        margin-left: 25px;
      }
      
    
    .variant-image{
      width: 210px;
    }
    
    .variant-image-selected{
      width: 240px;
    }
    
    .model-name{
        font-size: 12px;
        text-align: center;
        margin-bottom: 5px;
        margin-top: 2px;
        /* line-height: 1.7; */
        letter-spacing: 0.7px;
    }

    .selected-indicator{
        width: 30px;
        height: 2px;
        background-color: #000!important;
        margin: 0 auto;
    }

    .permission_policy{
      position: absolute;
      width: 100%;
      height: 100%;
      display: block;
      background-color: var(--background-color);
      z-index: 999;
      
    }

    .accept-container{
      width: 160px;
      border-radius: 50px ;
      text-align: center;
      font-size: 16px;
      background-color: var(--primary-color);
      margin: 0 auto;
      height: 50px;
      color: #fff;
      line-height: 45px;
      margin-top: 20px;
    }

    /* .accept-container:hover{
      background-color: #ffffff;
      border: 1px solid #42B1AC;
      color: #42B1AC;
    } */
 


    .title_policy_accordion{
      font-size: 16px;
      color: #888888;
      line-height: 50px;
    }

    .read-policy{
      font-size: 18px;
      color: var(--primary-color);
      line-height: 50px;
      font-weight: 400;
    }

    .accordion-close{
      width: 40%;
      background-color: #f1f1f1;
      border-radius: 10px;
      margin: 0 auto;
      margin-top: 10px;
      margin-bottom: 10px;
    
    }

    .flex{
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
   
    }
    .subtitle-policy{

    }

    .accordion-close p{
      margin: 0px;
    }
    .policy-open{
      background-color: #fff;
      max-height: calc(40vh - 40px);
      overflow: scroll;
      overflow-x: hidden;
      /* padding: 20px; */
      justify-content: left;
    }

    .left-accordion-policy{
      margin-left: 20px;
    }

    .right-accordion-policy{
      margin-right: 20px;
    }

    .title-policy{
      font-size: 16px;
      font-weight: bold;
      color: #000;
      /* padding: 10px; */
      padding-left: 20px;
      padding-right: 20px;
    }
  
    .paragrafo-policy{
      font-size: 14px;
      text-align: left;
      padding: 10px;
      padding-left: 20px;
      padding-right: 20px;
    }
   

    /* Stile della scrollbar */
::-webkit-scrollbar {
  width: 5px;  /* Larghezza */
}

/* Pulsante scrollbar */
::-webkit-scrollbar-button {
  background-color: #ccc;  /* Colore di sfondo */
}

/* Traccia scrollbar */
::-webkit-scrollbar-track {
  background-color: #f1f1f1;  /* Colore di sfondo */
}

/* Impostazione del thumb */
::-webkit-scrollbar-thumb {
  background-color: #ccc;  /* Colore di sfondo */
  border-radius: 10px;  /* Bordi arrotondati */
}

/* Stile del thumb quando si preme */
::-webkit-scrollbar-thumb:hover {
  background-color: #f1f1f1;  /* Colore di sfondo */
}

.error-display-camera{
  color:#BA1A1A;
}

.modal-backdrop{
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: transparent;
  z-index: 9;
  top: 0;
}



.modal-backdrop-loading{
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  /* background-color: transparent; */
  z-index: 19;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-backdrop-on-people{
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #00000060;
  /* background-color: transparent; */
  z-index: 19;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.modal-backdrop-on-people-screen{
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #000000;
  /* background-color: transparent; */
  z-index: 23;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.animation-container{
  width: 50px;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.modal-backdrop-on-scene{
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #00000095;
  /* background-color: transparent; */
  z-index: 19;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -20px;
}
.on-people-message{
  font-size: 28px;
  color: #fff;
}
.loading-message{
  font-size: 16px;
  color: #888888;
}

.refresh-page{
  /* right: 20px; */
  /* position: absolute; */
  top: 20px;
  width: 35px;
  height: 35px;
  z-index: 8;
  border-radius: 200px;
  /* background-color: white; */
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center; 
  grid-row: 1;
  grid-column: 3;
  justify-self: end; 
}

.icon-refresh-page{
  color: var(--primary-color);
  height: 80%;

}
.slider-position{
  width: 100%;
  position: absolute;
  z-index: 8;
  bottom: 27vh;
}

.slider-position-single{
  width: 100%;
  position: absolute;
  z-index: 8;
  bottom: 3vh;
}
.slider-container{
  width: 40%;
  margin: 0 auto;
}

.photo-container {
  position: fixed;  /* Posizionamento fisso rispetto al viewport */
  bottom: 18.5vh;     /* 27% dell'altezza del viewport dal basso */
  left: 50%;        /* Posiziona a metà orizzontalmente */

  /* Centra il contenitore orizzontalmente rispetto alla sua larghezza */
  transform: translateX(-50%);

  /* Altri stili come width, height, ecc. */
}

.take-photo{
  /* position: absolute;
  bottom: 24vh; */
  /* margin: 0 auto;
  margin-left: 40px; */
  margin-top: 5px;
  width: 130px;
  height: 140px;
  border-radius: 100px;
  z-index: 6;
  background-color: #fff;
  border: none;
  left: 50%;
  align-items: center;
  /* transform: translateX(-50%); */

}

.take-photo:active{
  background-color: #ccc;
}

.take-photo:hover{
  background-color: #ccc;
}

.return-on-page{
  left: 20px;
  position: absolute;
  top: 20px;
  width: 55px;
  height: 55px;
  z-index: 8;
  border-radius: 200px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.return-on-page-cat{
  position: absolute;
  width: calc(350px - 20px);
  height: 35px;
  z-index: 8;
  border-radius: 200px;
  display: flex;
  align-items: center;
  margin-top: -50px;
  margin-bottom: 20px;
  margin-left: 20px;
  top: 280px;
}

.navigation-mirror{
  padding-left: 20px;
  font-weight: bold;
}

.return-on-page-not-safari{
  left: 20px;
  position: absolute;
  top: 60px;
  width: 35px;
  height: 35px;
  z-index: 8;
  border-radius: 200px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-return-on-page{
  color: var(--primary-color);
  height: 60%;

}

.icon-view-on-app{
  color: var(--primary-color);
  filter: var(--custom-filter);
  height: 100%;

}

.share-modal{
  position: absolute;
  top: calc(50% - 225px);
    left: 50%;
  transform: translate(-50%, -50%);
  width: 590px;
  z-index: 10;
  height: 853px;
  background: #fff;

  padding: 10px;
}

.icon-share-photo{
  width: 50px;
  height: 50px;
  color: var(--primary-color);
}
.icon-take-photo{
  width: 45px;
  height: 45px;
  color: var(--primary-color);
  margin-bottom: 30px;
}

.icon-share-photo:hover{
  color: var(--primary-color);

}

.miniature-photo-div{
  width: 80px;
  height: 80px;
  position: absolute;
  z-index: 12;
  right: 20px;
  bottom: 19%;
  background-color: #fff;
  padding: 5px;
}

.close-icon{
  position: absolute;
  right: 0px;
  font-size: 18px;
  color: white;
}

.slider-menu-container{
  height: 85%;
}

.share-line-modal{
  background-color: #fff;
  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 15%;
  padding: 15px;
  position: absolute;
  width: calc(100% - 40px);
  z-index: 992;
}

/* Stili per il contenitore del form */
.share-line-modal form {
  display: grid; /* Usa un layout a griglia */
  gap: 10px; /* Distanza tra le righe e le colonne */
  padding: 15px;
  background: #fff; /* Colore di sfondo del form */
  border-radius: 5px; /* Bordo arrotondato */
  grid-template-rows: repeat(3, auto); /* Tre righe di altezza automatica */
}


.share-line-modal-two{
  bottom: 0;
  position: absolute;
  z-index: 992;
  height: 20%;
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  gap: 10px; /* Distanza tra gli elementi del form */
  padding: 15px;
  background-color: white;
}

.mesage-success{
  bottom: 0;
  position: absolute;
  z-index: 992;
  height: 10%;
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  gap: 10px; /* Distanza tra gli elementi del form */
  padding: 15px;
  background-color: white;
  color: var(--primary-color);
  text-align: center;
  padding-top: 60px;
  text-transform: uppercase;
  font-weight: bold;
}
/* Stili per il contenitore del form */
.share-line-modal-two form {
  display: grid; /* Usa un layout a griglia */
  gap: 10px; /* Distanza tra le righe e le colonne */
  padding: 15px;
  background: #fff; /* Colore di sfondo del form */
  border-radius: 5px; /* Bordo arrotondato */
  grid-template-rows: repeat(3, auto); /* Tre righe di altezza automatica */
}

/*  */
/* Stili per gli input */
/* .share-line-modal input[type="email"] {
  width: 100%; 
}

.share-line-modal input[type="text"] {
  width: 100%; /
} */

/* Stili per label e checkbox */
.share-line-modal label {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
}

.share-line-modal input[type="checkbox"] {
  /* Stili personalizzati per la checkbox se necessario */
}

/* Stili per il bottone di invio */
.share-line-modal button[type="submit"] {
  justify-self: center; /* Centra orizzontalmente il bottone nella griglia */
  padding: 10px 15px;
  background-color: var(--primary-color); /* Colore di sfondo */
  color: white; /* Colore del testo */
  border: none;
  border-radius: 5px; /* Bordo arrotondato */
  cursor: pointer; /* Cambia il cursore */
  font-size: 16px;
}

.share-line-modal button[type="submit"]:hover {
  background-color: var(--primary-color-dark); /* Colore al passaggio del mouse */
}


.slider-sub-container{
  height: 60%;
  position: absolute;
  right: 40px;
  top: 15%;
  z-index: 99;
}
.reset-menu-container{
  width: 15%;
  text-align: left;
  color: white;
  text-decoration: underline;
  font-size: 18px;
}

.icon-share-photo{
  margin: 15px;
}
.photo-modal{
  width: 100%;
  height: 100%;
  object-fit: contain;
}
@media only screen and (max-width: 1280px){
  .slider-container{
    width: 70%;
  }
}
@media only screen and (max-height: 900px) {
  .menu_container{
    height: 75% !important;
    overflow: hidden;
  }

  .slider-position{
    bottom: 27% !important;
  }
  .output_canvas{
    /* overflow: hidden; */
    margin: 0 auto; 
    position: absolute;
 left: 50%;
top: calc(50% );
transform: translate(-50%, -50%);
  }
}

@media only screen and (max-height: 700px) {
  .menu_container{
    height: 70% !important;
    overflow: hidden;
  }
 
  .slider-position{
    bottom: 32% !important;
  }
  .output_canvas{
    /* overflow: hidden; */
    margin: 0 auto; 
    position: absolute;
 left: 50%;
/* top: calc(50% - 15%); */
transform: translate(-50%, -50%);
  }
}

@media only screen and (max-width: 780px) {

  /* .share-modal{
    position: absolute;
    width: 80%;
    z-index: 10;
    height: 60%;
    background: #fff;
    left: 7% ;
    top: 35px;
    padding: 10px;
  } */

  .menu_container{
    height: 80%;
    overflow: hidden;
  }


  .output_canvas{
    /* overflow: hidden; */
    margin: 0 auto; 
    position: absolute;
 left: 50%;
/* top: calc(50% - 13%); */
transform: translate(-50%, -50%);
  }
  .slider-position{
    bottom: 26%;
  }

  .miniature-photo-div{
    width: 70px;
    height: 90px;
    position: absolute;
    z-index: 12;
    right: 10px;
    bottom: 35%;
    background-color: #fff;
    padding: 5px;
  }

  .miniature-photo-div-single{
    width: 70px;
    height: 90px;
    position: absolute;
    z-index: 12;
    right: 10px;
    bottom: 15%;
    background-color: #fff;
    padding: 5px;
  }

  .take-photo{
      bottom: 35vh;
  }





  .logo_iamge_policy{
    width: 20%;
  }
  .slider-container{
    width: 90%;
  }

  .subtitle-policy{
    width: 90%;
    margin: 0 auto;
  }
  
  .accordion-close{
    width: 90%;
  }
  
  .title_policy_accordion{
    line-height: 40px;
  }
  .read-policy{
    line-height: 40px;
  }
}


@media only screen and (max-width: 480px) {

 .title_glasses{
  font-size: 14px;
  margin-top: 5px;
 }

  .reset-menu-container{
    font-size: 16px;
  }
  .select_size_title{
    margin-right: 2px;
    font-size: 10px;
    padding: 5px;
    color: #fff;
    line-height: 50px;
    font-weight: 500;
  }
  

  .refresh-page{
    /* right: 0px;
    position: absolute; */
    top: 15px;
    width: 30px;
    height: 30px;
    z-index: 8;
    border-radius: 200px;
    /* background-color: white; */
    display: flex;
    justify-content: center;
    align-items: center;
    grid-row: 1;
  grid-column: 3;
  justify-self: end; 
  }

  .variant_card + .variant_card {
      margin-left: 25px;
    }
    
  
  .variant-image{
    width: 90px;
    margin-top: 20px;
  }
  
  .variant-image-selected{
    width: 110px;
    margin-top: 25px;
  }


  .logo_iamge_policy{
    width: 20%;
  }
  .slider-container{
    width: 90%;
  }

  .subtitle-policy{
    width: 90%;
    margin: 0 auto;
  }
  
  .accordion-close{
    width: 90%;
  }
  
  .title_policy_accordion{
    line-height: 40px;
  }
  .read-policy{
    line-height: 40px;
  }



  .share-modal{
    /* position: absolute;
    width: 80%;
    z-index: 10;
    height: 60%;
    background: #fff;
    left:7%;
    top: 20px;
    padding: 10px; */
    /* position: absolute;
    width: 60%;
    z-index: 10;
    height: 60%;
    background: #fff;
    left: 20%;
    top: 20px;
    padding: 10px; */
  }

  .share-modal-single{
    position: absolute;
    width: 80%;
    z-index: 10;
    height: 60%;
    background: #fff;
    left:7%;
    top: 20%;
    padding: 10px;
  }

  .logo_iamge_policy{
    width: 30%;
  }
  .title{
    height: 0%;
    font-size: 12px;
  }
  
.logo_policy_arshades{
margin-top: 5%;
height: 18%;
}
.subtitle-policy{
width: 90%;
/* height: 8vh; */
padding: 20px;
margin: 0 auto;
}

.accordion-close{
width: 90%;
}

.title_policy_accordion{
line-height: 40px;
}
.read-policy{
line-height: 40px;
}
}

.higt-definition-banner{
  height: 40px;
  background-color: #000;
  width: 100%;
  position: absolute;
  z-index: 99;
  display: flex;
flex-direction: row;
padding: 5px;
}

.higt-definition-icon{
  width: 15% ;
  display: flex;
  justify-content: end;
}

 .higt-definition-icon-img{
  height: 40px;
}

.higt-definition-message{
display: flex;
width: 60%;
flex-direction: column;
justify-content: left;
padding: 4px;
margin-left: 10px;
}

.higt-definition-message-title{
  font-size: 14px;
  font-weight: bold;
  color: #fff;
}

.higt-definition-message-subtitle{
  font-size: 12px;
  color: #ccc;
}

.higt-definition-button-open{
  background-color: var(--primary-color);
  border-radius: 15px;
  color: #fff;
  font-size: 13px;
  font-weight: 700;
  height: 28px;
  line-height: 28px;
  margin-top: 2px;
  text-align: center;
  width: 70px;
}

.higt-definition-button{
  width: 25%;
  display: flex;
  justify-content: end;
  padding: 5px;
  margin-right: 10px;
}

.slider-container .rc-slider-handle:active {
  box-shadow: none !important;
}


@media only screen and (max-width: 400px){
  .item-dot-size{
    border-radius: 50px;
    background-color:var(--primary-color);
    width: 25px;
    height: 25px;
    /* margin-top: 10px; */
    color: white;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    border:1px solid #000;
  }
  
  .item-dot-size-unselected{
    border-radius: 50px;
    background-color:#ffffff ;
    width: 25px;
    height: 25px;
    /* margin-top: 10px; */
    color: #000;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    border:1px solid #000;
  }
  .output_canvas{
    /* overflow: hidden; */
    margin: 0 auto; 
    position: absolute;
 left: 50%;
/* top: calc(50% - 13%); */
transform: translate(-50%, -50%);
  }
}


.debub-log{
  z-index: 99;
  background-color: black;
  color: #ffffff;
  position: absolute;
}

.menu_vto_catalogue{
  display: flex;
  flex-direction: row;
}





/* PAGINA CATALOGO */

.wrapper-catalogue{
  max-width: 900px;
  margin: 0 auto;
  overflow: hidden;
}

.modal-backdrop-filter{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: transparent;
  z-index: 12;
}

.filter-modal{
  position: fixed;
  width: 100%;
  max-width: 900px;
  height: calc(100% - 150px);
  bottom: 0;
  background-color: #fff;
  border-radius: 15px 15px 0 0;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.09);
  z-index: 13;
}

.catalogue_header{
  position: fixed;
  top: 0;
  /* margin-top: 20px; */
  background-color: white;
  padding-bottom: 0px;
  margin-left: 10px;
  width: calc(900px - 20px);
  display: flex;
  flex-direction: row;
  z-index: 10;
  background-color: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.09);
  border-radius: 0 0 15px 15px;
}

.catalogue_header_cover{
  position: fixed;
  top: 0;
  height: 200px;
  /* margin-top: 20px; */
  background-color: white;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  padding-bottom: 0px;
  margin-left: 10px;
  width: calc(900px - 20px);
  display: flex;
  flex-direction: row;
  background-size: cover !important;
  background-position: center 35% !important;
  /* color: white; */
  z-index: 10;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.09); */
  /* border-radius: 0 0 15px 15px; */
  text-align: center;
  text-transform: uppercase;
}
.catalogue_title{
  line-height: 100px;  /* stesso valore dell'altezza */
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 27px;
}
.catalogue_image{
  width: 100px;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.09); */
  border-radius: 0 0 0 15px;
}

.start-new-session{
  position: absolute;
  z-index: 101;
  width: 60%;
  height: 80px;
  background-color: var(--primary-color);
  border-radius: 25px;
  bottom: 15%;
  margin: 0 auto;
  color: black;
  text-transform: uppercase;
}

.start-new-session-entering,
.start-new-session-exiting,
.start-new-session-exited {
  opacity: 0;
  transition: opacity 2000ms ease-in;
}

.start-new-session-entered {
  opacity: 1;
  transition: opacity 3000ms ease-in;
  position: absolute;
  z-index: 101;
  width: 60%;
  height: 80px;
  background-color: var(--primary-color);
  border-radius: 25px;
  bottom: 15%;
  margin: 0 auto;
  color: white;
  text-transform: uppercase;
  font-size: 35px;
  line-height: 80px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

}


.catalogue_logo_image{
  width: 17%;
    border-radius: 0 0 0 15px;
    position: absolute;
    top: 50%;
    left: 25%;
    transform: translate(-50%, -50%);
}

.catalogue_name{
font-size:16px;
/* padding: 20px;
padding-top: 10px;
padding-bottom: 10px; */
padding-left: 40px;
font-weight: bold;
}

.catalogue-info-container{
  display: flex;
  flex-direction: column;
  align-self: center;
}

.filter-icon{
  margin-right: 20px;
  display: flex;
  flex-direction: row;
}

.filter-icon-img{
  width: 20px;
}
.filter-text{
  margin-right: 10px;
}
.filter-popUp{
  margin-left: auto;
  align-self: center;
  display: flex;
  flex-direction: row;
}

.catalogue_pay_off{
  font-size:14px;
  padding-left: 40px;

  /* padding: 20px;
  padding-top: 0px; */
}

.line-header{
  margin: 0 auto;
  text-align: center;
  padding: 0px;
  padding-bottom: 20px;
}

.line-container {
  display: flex;
  /* justify-content: center; */
  flex-wrap: wrap;
  max-width: 900px;
  margin-top: 150px;

}



.line-grid-item {
  flex-basis: calc(25% - 20px);
  margin: 10px;
}



.line-grid-item-line {
  flex-basis: calc(50% - 20px);
  margin: 10px;
  display: block;
  height: calc(50% - 20px); /* Stabilisci una altezza identica alla larghezza per ottenere un quadrato */
}


.card-glasses {
  background: white;
  width: 100%;
  text-align: center;
    position: relative;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.09);
  border-radius: 15px;
}

.line-grid-item-cat {
  flex-basis: calc(50% - 20px);
  margin: 10px;
  display: block;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.09);
  border-radius: 15px;
  position: relative;
  padding-top: calc(50% - 20px); /* Questo determina l'aspetto (quadrato in questo caso) */
  overflow: hidden;
}

.line-container-brand {
  display: flex;
  flex-wrap: wrap;
  max-width: 900px;
  margin-top: 280px;  
}


.card-glasses-brand {
  width: 100%;
  text-align: center;
    position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center;

}



.card-glasses-img{
  max-width: 100%;
  max-height: 100%;
  margin-top: 15px;
}

.card-glasses-content {
  padding: 10px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
}

.container-card-glasses-img{
  height: 95px;
}
.container-card-glasses-img-info{
height: 95px;
bottom: 0;
position: absolute;
text-align: center;
background: white;
width: 100%;
display: flex;
    align-items: center;  /* Centra verticalmente */
    justify-content: center;  /* Centra orizzontalmente, opzionale se vuoi centrare anche in orizzontale */
}
.try-now-action{
  border-radius: 5px;
  width: 80%;
  background-color: var(--secondary-color);

  margin: 0 auto;
  color: #fff;
  padding: 5px;
  margin-top: 10px;
}

.view-3d-action{
  border-radius: 5px;
  width: 80%;
  background-color: var(--primary-color);
  margin: 0 auto;
  color: #fff;
  padding: 5px;
  margin-top: 5px;
}

.line-header{
  color: var(--secondary-color);
  font-weight: bold;
}
.glasses_card{
  width: 200px;
  height: 200px;
  background-color: white;
  padding: 10px;
}

.catalogue_container{
  /* overflow: scroll; */
}

.poweredby {
  font-size: 8px;
  position: absolute;
  bottom: 10px;
  left: 50%;
  color: var(--font-color);
  transform: translate(-50%, 0);
}

.poweredbyARShades {
  font-size: 10px;
  font-weight: bold;
  color: var(--font-color);
}


.badge-container{
  border-radius: 15px;
  padding: 00px 5px 0px 5px;
  height: 16px;
  line-height: 14px;
  font-weight: 500;
  left: 5px;
  top: 5px;
  font-size: 14px;
  position: absolute;
  }

  .badge-container-icon{
    border-radius: 50px;
    height: 22px;
    width: 22px;
    line-height: 14px;
    left: 5px;
    top: 5px;
    font-size: 12px;
    position: absolute;
  }

  .badge-icon{
    height: 18px;
    width: 18px;
    padding-top: 2px;
  }

  
@media only screen and (max-width: 800px){
  .line-grid-item {
    flex-basis: calc(50% - 20px);
  }

  .catalogue_header{
    width: calc(100% - 30px);
  }
  .catalogue_header_cover{
    width: calc(100% - 20px);
  }
  .catalogue_logo_image{
    width: 30%;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.transition-screen {
  z-index:800;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centra orizzontalmente */
  align-items: center; /* Centra verticalmente */
}

.fade-out {
  animation: fadeOut 0.5s ease forwards;
  z-index: 0;
  display: absolute; /* Usa flexbox */
  justify-content: center; /* Centra orizzontalmente */
  align-items: center; /* Centra verticalmente */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.dataModeltRE{
  z-index: 999;
  position: absolute;
}

.App-Mirror{
  height: 100vh;
  width: 100%;
  position: absolute;
  margin: 0px;
  padding: 0px;
  overflow:hidden;
}



/* Stili per il contenitore footer */
.footer-vto-menu {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 22vh; 
  display: flex;
  flex-direction: column;
  scrollbar-width: none; /* per Firefox */
  background-color: white;
}

/* Stili per il componente superiore */
.menu_vto {
  flex: 0 0 10vh; /* 10% dell'altezza della viewport */
  overflow-x: auto;
  width: 100%;
  height: 80%;
  position: absolute;
  background-color: #ffffff;
  -webkit-overflow-scrolling: touch;
  z-index: 2; 
  padding-top: 20px;  
  padding-bottom: 20px;
  /* display: none;  */
   /* Altri stili come background-color, ecc. */
}

/* Nascondi la barra di scorrimento mantenendo la funzionalità di scroll */
.menu_container::-webkit-scrollbar {
  display: none !important;
}

/* Per Firefox */
.menu_vto {
  scrollbar-width: none;
}

/* Stili per il componente inferiore */
.componente-inferiore {
  flex: 0 0 15vh; /* 15% dell'altezza della viewport */
  /* Stili aggiuntivi per il componente inferiore */
}

.menu_container-desk{
  padding: 0px;
}

.footer_vto{
  flex: 0 0 10vh; /* 10% dell'altezza della viewport */
  overflow-x: auto;
  width: 100%;
  height: 10vh;
  position: absolute;
  background-color: black;
  -webkit-overflow-scrolling: touch;
  z-index: 2;    
  bottom: 0;
}

.reset-menu-container{
  margin-top: 20px;
}

.clipped-button {
  background-color: transparent; /* Rendi lo sfondo trasparente */
  border: none; /* Rimuovi il bordo */
  cursor: pointer; /* Cambia il cursore a indicare che è cliccabile */
  color: inherit; /* Eredita il colore del testo */
  display: flex; /* Usa Flexbox */
  align-items: center; /* Centra gli elementi verticalmente */
  justify-content: center; /* Centra gli elementi orizzontalmente (opzionale) */
  position: absolute;
  /* top: 20px; Allinea in alto */
    /* background: white; */
  border-radius: 25px;
  color: black;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: bold;
}

.clipped-icon-button {
  color: var(--primary-color); /* Colore dell'icona */
  font-size: 48px; /* Dimensione dell'icona */
  margin-right: 8px; /* Aggiungi un margine a destra per spazio tra icona e testo */
}

.menu-container-vto {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 8vh; /* 10% dell'altezza dello schermo */
  color: black;
  text-transform: uppercase;
  font-size: 20px;
  z-index: 100;
  padding-left: 20px;
  padding-right: 20px;
  width: calc(100% - 40px);
  position: absolute;
  z-index: 100;
  background: rgba(255, 255, 255, 0.21);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7.1px);
    -webkit-backdrop-filter: blur(7.1px);
}

.menu-item-vto  {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%; /* Larghezza per le colonne esterne */
  text-decoration: underline;
  z-index: 100;
  font-size: 24px;
}

.menu-item-vto:nth-child(2) {
  width: 50%; /* Larghezza per la colonna centrale */
}


.message{
  color:'black';
  font-size:16px;
}
.cs-input-button{
  height: 80px;
  border-radius: 10px;
  border: 0.2px solid #fafafa;
  padding: 5px;
  background-color: #e1e1e1;
  width: 80%;
  bottom: 40px;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  border: 1px solid #ccc;
  background-color: var(--primary-color); /* Colore di sfondo */
  color: white;
  text-transform: uppercase;
  font-size: 30px;
}

.cs-input-button-two{
  background-color: var(--primary-color) !important; /* Colore di sfondo */
color: white;
text-transform: uppercase;
  height: 80px;
  border-radius: 10px;
  border: 0.2px solid #fafafa;
  padding: 5px;
  width: 80%;
  bottom: 20px;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  border: 1px solid #ccc;
  font-size: 30px;
}

.cs-input{
  background-color: #f8f8f8;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  bottom: 110px;
  font-weight: 700;
  height: 80px;
  left: 50%;
  padding-left: 20px;
  position: absolute;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
  width: calc(80% - 20px);
  font-size: 25px;

}

