.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    margin: 0 auto;
    width: 880px;
    /* overflow-y: hidden;  */
    margin-top: 30%; 
    /* overflow-x: hidden; */
    perspective: 1876px;
   }

   .card-container-full {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    /* overflow-y: hidden;  */
    margin-top: 30%; 
    /* overflow-x: hidden; */
    perspective: 1876px;
   }



   .card-container-glasses{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
        align-items: center; 
    margin: 0 auto;
    width: 100%;
    overflow-y: auto; /* Aggiunge lo scrolling verticale se necessario */
    margin-top: 30%; /* Margin top di default (può essere sovrascritto in linea) */
    /* height: calc(100vh - 100px); Imposta un'altezza fissa, adatta in base al layout */
    perspective: 1876px;

   }

/* Stili specifici per CardContainerHorizontal */
.card-horizontal-container {
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    gap: 24px;
    padding: 0 10%;
    scrollbar-width: none; /* Firefox */
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth; /* Aggiungi questa linea */
  }
  
  .card-horizontal {
    scroll-snap-align: center;
    flex-shrink: 0;
    width: 540px;
    height: 40vh;
    border-radius: 25px;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.21);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7.1px);
    -webkit-backdrop-filter: blur(7.1px);
    border: 1px solid rgba(255, 255, 255, 0.48);
    /* transition: transform 0.3s, width 0.3s, height 0.3s, box-shadow 0.3s, margin-top 0.3s; Aggiungi questa linea */
    margin-top: 60px;
}
  
  .card-horizontal.active-card {
    width: 620px;
    height: 50vh;
    box-shadow: 0 6px 40px rgba(0, 0, 0, 0.3);
    margin-top: 0px;
  }


  @media (max-width: 1366px) {

   
    
    }



  
