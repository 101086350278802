.container{
    height: 30vh;
    position: relative;
    text-align: center;
    margin-top: 160px;
    z-index: 10;
    width: 100%;
    /* background-color: white; */
    /* color: black; */
}

/* Model Viewer for Generation */
/* model-viewer{
    width: 512px;
    position: absolute;
    height: 288px;  
} */

model-viewer{
    width: 100%;
    position: absolute;
    height: 50vh;  
    top: -190px;
    
}

.instantanea{
    position: absolute;
    bottom: 30px;
    width: 100%;
    height: 80px;
     background-color: white;
    z-index: 99;
}

.animationButton{
    z-index: 99;
    position: absolute;
}

.clipped_button {
    background-color: transparent; /* Rendi lo sfondo trasparente */
    border: none; /* Rimuovi il bordo */
    cursor: pointer; /* Cambia il cursore a indicare che è cliccabile */
    color: inherit; /* Eredita il colore del testo */
    display: flex; /* Usa Flexbox */
    align-items: center; /* Centra gli elementi verticalmente */
    justify-content: center; /* Centra gli elementi orizzontalmente (opzionale) */
    position: absolute;
    /* top: 20px; Allinea in alto */
      /* background: white; */
      left: 50%;
      transform: translate(-50%);
    border-radius: 25px;
    color: black;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: bold;
    color: white;
    padding: 10px;
    z-index: 25;
  }
  
  .clipped-icon-button {
    /* color: var(--primary-color);  */
    color: white;
    font-size: 48px; /* Dimensione dell'icona */
    margin-right: 8px; /* Aggiungi un margine a destra per spazio tra icona e testo */
  }

  .clipped_button svg{
    display: block !important;
  }