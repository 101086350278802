.variantMenuContainer {
        position: relative;
        height: 80px;
        color: black;
        display: flex;
        flex-direction: row;
        overflow:visible;
        align-self: center;
        z-index: 14;
}

.line {
        margin-top: 80px;
        display: none;
}

.line_image {
        width: 100%;
        position: absolute;
        top: -40px;
        left: 0px;
        display: none;
}

.buttons_container{
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center; /* Optional, se vuoi anche l'allineamento verticale */
        gap: 120px; 
}

.box {
        height: 90px;
        /* width: 9%; */
        border: none;
        padding: 20px;
}

.box .rectangle {

        border: 1px solid;
        border-color: #ffffff;
        border-radius: 100px;
        height: 70px;
        left: 0;
        opacity: 0.5;
        top: 0;
        width: 70px;
}

.chooseColor{
        color: #ffffff;
        font-family: "Inter-SemiBold", Helvetica;
        font-size: 28px;
        font-weight: 600;
        height: 36px;
        letter-spacing: 0;
        line-height: normal;
        text-align: center;
        width: 569px;
}
.glassName{
        color: #ffffff;
  font-family: "Inter-SemiBold", Helvetica;
  font-size: 48px;
  font-weight: 600;
  margin-top: 60px;
  height: 53px;
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
  
  width: 460px;
}

.button{
        border:solid 1px ;
        padding: 20px;
        border-radius: 10px;
        background-color: transparent;
        color: white;
        width: 400px;
        margin-top: 40px;
        font-size: 40px;
        background: rgba(255, 255, 255, 0.21);
        backdrop-filter: blur(7.1px);
        border: 1px solid rgba(255, 255, 255, 0.48);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

.brandimage {
width: 150px;
height: 87px;
align-self: center;
align-content: center;
}

.lottie_animation{
        margin-bottom: -40vh;
        z-index: 15;
}

.lottie_animation svg{
         display: block !important;
        height: 30vh;
}




    