.glasses-container {
    display: flex;
    flex-wrap:nowrap;
    align-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding-top: 100px;
    height: 80%;
    position: relative;
    overflow-y:visible;
    margin-top: -130px;
    perspective: 1836px;
   }
