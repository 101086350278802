.searchBarContainer {
    width: 80%;
    height: 80px;
    padding: 10px;
    border: none;
    overflow: hidden;
    border-radius: 16px;
    cursor: pointer;
    color: white;
    background: rgba(255, 255, 255, 0.21);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7.1px);
    -webkit-backdrop-filter: blur(7.1px);
    border: 1px solid rgba(255, 255, 255, 0.48);
    display: flex;
    align-items: center;
    position: relative;
    margin: 40px;
  }
  
  .searchInput {
    margin-left: 100px; /* Distanza a sinistra per l'icona */
  margin-right: 100px; /* Distanza a destra per l'icona */
  border: none;
  border-bottom: 1px solid white; /* Linea inferiore */
  background-color: transparent;
  color: white;
  font-size: 32px;
  outline: none;
  width: calc(100% - 200px); /* Sottrae le dimensioni dei margini */
  }

  .searchInput::placeholder{
    color: white;
  }
  
  .iconLeft, .iconRight {
    position: absolute;
    width: 64px; /* Dimensioni dell'icona, regola secondo necessità */
    height: 64px;
    cursor: pointer;
  }
  
  .iconLeft {
    left: 15px; /* Distanza dal bordo sinistro */
  }
  
  .iconRight {
    right: 15px; /* Distanza dal bordo destro */
  }
  
  .searchButton {
    flex: 0 0 20%;
    border: none;
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
    cursor: pointer;
    font-size: 18px;
    height: 100%;
  }
  
  