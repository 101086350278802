html, body {
  font-family: 'Dongle', sans-serif;
  margin: 0;
  padding: 0;
}

.text-container {
  z-index: 100;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  font-size: 96px;
  color: white;
  opacity: 0.8;
  user-select: none;
  text-shadow: 1px 1px rgba(0,0,0,0.1);
}

// :root {
//   --color-bg1: rgba(236, 225, 243, 0.7); /* Una tonalità più scura di viola */
//   --color-bg2: rgb(166, 170, 211); /* Un blu scuro profondo */
//   --color1: 85, 105, 128; /* Una sfumatura di blu-grigio scuro */
//   --color2: 255, 255, 190; /* Un viola scuro, meno saturo */
//   --color3: 60, 130, 155; /* Un ciano scuro */
//   --color4: 150, 40, 40; /* Un rosso scuro, meno saturo */
//   --color5: 130, 130, 30; /* Un verde oliva scuro */
//   --color-interactive: 100, 70, 155; /* Un viola intermedio scuro */
//   --circle-size: 80%;
//   --blending: hard-light;
// }


:root {
  --color-bg1: rgba(245, 245, 245, 0.8); // Grigio chiaro
  --color-bg2: rgba(230, 230, 230, 0.9); // Bianco sporco
  --color1: 255, 255, 255; // Bianco
  --color2: 245, 245, 245; // Grigio chiaro
  --color3: 224, 224, 224; // Grigio argento
  --color4: 192, 192, 192; // Grigio più scuro
  --color5: 220, 220, 220; // Grigio medio
  --color-interactive: 255, 255, 240; // Bianco con sfumature gialle
  --circle-size: 70%;
  --blending: overlay; // Effetto sovrapposto
}

@keyframes moveInCircle {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes moveVertical {
  0% {
    transform: translateY(-50%);
  }
  50% {
    transform: translateY(50%);
  }
  100% {
    transform: translateY(-50%);
  }
}

@keyframes moveHorizontal {
  0% {
    transform: translateX(-50%) translateY(-10%);
  }
  50% {
    transform: translateX(50%) translateY(10%);
  }
  100% {
    transform: translateX(-50%) translateY(-10%);
  }
}

.gradient-bg {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow-y: hidden;
  overflow-x: hidden;
  background: linear-gradient(60deg, var(--color-bg1), var(--color-bg2));
  background-image: url("https://firebasestorage.googleapis.com/v0/b/arshades-7e18a.appspot.com/o/FCMImages%2FDepositphotos_2087680_XL%20(1)%20(1)%20(1).jpg?alt=media&token=78d5773f-7377-45ae-baf7-8a89d0245f91");
  background-size: cover;


  .gradients-container {
    filter: blur(20px);
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(245, 245, 245, 0.15);
  }

  .g1 {
    position: absolute;
    background: radial-gradient(circle at center, rgba(var(--color1), 0.2) 0, rgba(var(--color1), 0) 50%) no-repeat;
    mix-blend-mode: var(--blending);
    width: var(--circle-size);
    height: var(--circle-size);
    top: calc(50% - var(--circle-size) / 2);
    left: calc(50% - var(--circle-size) / 2);
    transform-origin: center center;
    animation: moveVertical 20s ease infinite;
  }

  .g2 {
    position: absolute;
    background: radial-gradient(circle at center, rgba(var(--color2), 0.4) 0, rgba(var(--color2), 0) 50%) no-repeat;
    mix-blend-mode: var(--blending);
    width: var(--circle-size);
    height: var(--circle-size);
    top: calc(50% - var(--circle-size) / 2);
    left: calc(50% - var(--circle-size) / 2);
    transform-origin: calc(50% - 400px);
    animation: moveInCircle 40s reverse infinite;
  }

  .g3 {
    position: absolute;
    background: radial-gradient(circle at center, rgba(var(--color3), 0.5) 0, rgba(var(--color3), 0) 50%) no-repeat;
    mix-blend-mode: var(--blending);
    width: var(--circle-size);
    height: var(--circle-size);
    top: calc(50% - var(--circle-size) / 2 + 200px);
    left: calc(50% - var(--circle-size) / 2 - 500px);
    transform-origin: calc(50% + 400px);
    animation: moveInCircle 60s linear infinite;
  }

  .g4 {
    position: absolute;
    background: radial-gradient(circle at center, rgba(var(--color4), 0.4) 0, rgba(var(--color4), 0) 50%) no-repeat;
    mix-blend-mode: var(--blending);
    width: var(--circle-size);
    height: var(--circle-size);
    top: calc(50% - var(--circle-size) / 2);
    left: calc(50% - var(--circle-size) / 2);
    transform-origin: calc(50% - 300px);
    animation: moveHorizontal 40s ease infinite;
  }

  .g5 {
    position: absolute;
    background: radial-gradient(circle at center, rgba(var(--color5), 0.4) 0, rgba(var(--color5), 0) 50%) no-repeat;
    mix-blend-mode: var(--blending);
    width: calc(var(--circle-size) * 1.5);
    height: calc(var(--circle-size) * 1.5);
    top: calc(50% - var(--circle-size));
    left: calc(50% - var(--circle-size));
    transform-origin: calc(50% - 600px) calc(50% + 150px);
    animation: moveInCircle 30s ease infinite;
  }



  .interactive {
    position: absolute;
    background: radial-gradient(circle at center, rgba(var(--color-interactive), 0.8) 0, rgba(var(--color-interactive), 0) 50%) no-repeat;
    mix-blend-mode: var(--blending);

    width: 100%;
    height: 100%;
    top: -50%;
    left: -50%;

    opacity: 0.7;
  }
}
