/* FlexContainer.css */
.flex-container {
    display: flex;
    flex-direction: column; /* Allinea i componenti in colonna */
    justify-content: center; /* Centra i componenti verticalmente */
    align-items: center; /* Centra i componenti orizzontalmente */
    width: 100%; /* Prende l'intera larghezza */
    perspective: 1874px; /* Ad esempio */

    z-index: 12;
    position: absolute;
  }
  