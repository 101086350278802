/* variables.css */
:root {
    --background-color: white;
    --primary-color: #42B1AC;
    --secondary-color: #42B1AC;
    --font-color: black;
    --custom-filter: invert(55%) sepia(68%) saturate(345%) hue-rotate(128deg) brightness(98%) contrast(91%);
}


/*Safilo*/
/* :root {
    --background-color: white;
    --primary-color: #86ACCA;
    --secondary-color: #003b65;
    --font-color: black;
    --custom-filter: invert(80%) sepia(44%) saturate(704%) hue-rotate(176deg) brightness(85%) contrast(84%); 
} */


/* Boss EyeWear */
/* :root {
    --background-color: black;
    --primary-color: #987147;
    --secondary-color: black;
    --font-color: white;
    --custom-filter: invert(47%) sepia(18%) saturate(975%) hue-rotate(350deg) brightness(94%) contrast(93%);
} */