


.footer {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
    margin: 0;
    overflow-y: hidden;
    z-index: 10;
    bottom: 5px;
    /* color: white;
    background: rgba(255, 255, 255, 0.21);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7.1px);
    -webkit-backdrop-filter: blur(7.1px);
    border: 1px solid rgba(255, 255, 255, 0.48); */
  }

  .poweredBy{
    font-size: 22px;
    color: white;
    padding: 30px;
  }

  .terms{
    font-size: 36px;
    color: white;
    padding: 10px;

  }
  .footer::-webkit-scrollbar{
    display: none !important;
  }
  .footer-image::-webkit-scrollbar{
    display: none !important;
  }
  
  .footer-image {
    height: 200px;
  }