.header-container{
  flex-direction:column;
  color:white;
  z-index:99;
  position: relative;
  left: '100%';


  
}
.subtitle-container{
  flex-direction:column;
  color:white;
  position: relative;
  justify-content: center;

  
}

.title {
  font-size: 60px;
  font-weight: bold;
  text-align: center;
  color:white;
  margin-bottom: 25px;
}

.subtitle {
  font-size: 40px;
  font-weight: 700px;
  text-align:justify;
  color:white;
  margin-bottom: 20px;
  padding-left: 10%;
  padding-right: 10%;

}
.subtitle2 {
  font-size: 14px;
  font-weight: 700px;
  text-decoration: underline;
  text-align: center;
  color:white;
  margin-bottom: 30px;
  border: none;
  justify-content: center;
  align-items: center;
  align-self: center;
  display: flex;
  flex-direction: row;
}

.videoContainer {
  display: 'flex';
  flex-direction: column;
  justify-content: 'center';
  align-items: 'center';
  align-content: center;
  width: 100%;
  margin: 0px auto;
  position: relative;
  z-index: 9px;
  margin: 60px auto;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
margin-bottom: 0px;
}

.videoContainerTwo{
  display: 'flex';
  flex-direction: column;
  justify-content: 'center';
  align-items: 'center';
  align-content: center;
  width: 100%;
  margin: 0px auto;
  position: relative;
  z-index: 9px;
  margin: 60px auto;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
margin-bottom: 0px;
}
/* .video {
  width: 80%;
  height: 400px;
  background-color: black;
  margin: 0px 10%;
  
} */
.playButton {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: white;
  font-size: 44px;
  left:500px;
  top:40%;

}

.video {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
}


.videoContainerThree {
  display: flex;
  flex-direction: column;
  align-items: center;
  display: 'flex';
  flex-direction: column;
  align-items: 'center';
  align-content: center;
  width: 100%;
  margin: 0px auto;
  position: relative;
  z-index: 9px;
  margin: 60px auto;
}

.titlesContainer {

  display: flex;
  overflow-x: auto;
  width: 80%;
  justify-content: space-around;
  color: white;
  padding: 20px;
  text-transform: uppercase;
}

.selectedTitle {
  color: rgba(255, 255, 255, 0.693);
  font-weight: bold;
  text-decoration: underline;
  /* Aggiungi qui altri stili se necessario */
}

.titlesContainer h3 {
  cursor: pointer; /* Cambia il cursore per indicare che sono cliccabili */
  margin: 10px; /* Aggiusta i margini secondo le tue esigenze */
}

.videoTwo {
  width: 80%;
  max-width: 100%; /* Assicurati che il video non superi la larghezza del contenitore */
}
