.card-glasses-img{
    width: 100%;
    justify-self: center;
}

.container-card-glasses-img{
    /* height: 55px; */
    margin-top: 70px;
}
.card-glasses{
    width: 256px;
    height: 350px;
    border: 1px solid white;
    margin: 20px;
    overflow: hidden;
    border-radius: 5px; /* Bordi leggermente arrotondati */
    /* box-shadow: 0px -2px 5px rgba(255, 255, 255, 0.481);  */
    border: none; /* Nessun bordo */
    cursor: pointer; /* Cursor mano */
    margin: 15px;
    /* background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px); */
    color: white;
    background: rgba(255, 255, 255, 0.21);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7.1px);
    -webkit-backdrop-filter: blur(7.1px);
    border: 1px solid rgba(255, 255, 255, 0.48);
}



/* Nome specifico per le card in MirrorGlasses */
/* Stili specifici per MirrorGlassesCardHorizontal */
.mirror-horizontal-card {
    scroll-snap-align: center;
    flex-shrink: 0;
    /* width: 540px;
    height: 40vh; */
    /* border-radius: 5px;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.21);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7.1px);
    -webkit-backdrop-filter: blur(7.1px);
    border: 1px solid rgba(255, 255, 255, 0.48); */
    /* transition: transform 0.3s, width 0.3s, height 0.3s; */
    margin-top: 40px;
}

.mirror-horizontal-card.active-card {
    /* width: 620px;
    height: 50vh; */
    /* box-shadow: 0 6px 40px rgba(0, 0, 0, 0.3);
    margin-top: -40px; */
}


      
.brandimage{
    width: 50%;
    /* height: 57px; */
    align-self: center;
    align-content: center;
      
}

.card-glasses-name{
    font-size:34px;
    text-align: center;
    margin: 30px;
    font-weight: bold;
    margin-top: 80px;
}
.card-glasses-name-variant{
    font-size:12px;
    text-align: center;
    margin: 30px;
}
.brandcontainerLogo{
    flex-direction: row;
    display: flex;
    align-items: center;
    width: inherit;
    justify-content: center;
    align-self: center;
    align-content: center;
    max-height: 20px;
    margin-top: 80px;
}
.colorList{
    
    flex-direction: row;
    display: flex;
    align-items: center;
    width: inherit;
    justify-content: center;
    align-self: center;
    align-content: center;
    margin-top: 140px;
}

.box {
    height: 42px;
    width: 20%;
    margin: 5px;
  }
  
  .box .rectangle {
    background: linear-gradient(
      180deg,
      rgb(20.88, 93.01, 133.59) 0%,
      rgba(47.07, 31.37, 7.82, 0.98) 0.01%,
      rgb(201.27, 194.67, 36.18) 58%
    );
    border: 1px solid;
    border-color: #ffffff;
    border-radius: 5px;
    height: 42px;
    left: 0;
    opacity: 0.5;
  }

