/* .menu-container {
    display: grid;
    grid-template-columns: 25% 50% 25%;
    justify-content: center; 
    align-items: center; 
    height: 10%; 
    background-color: transparent; 
  }
.menu-container-2 {
    display: grid;
    grid-template-columns:  100% 50%;
    justify-content: center; 
    align-items: center; 
    height: 10%; 
    background-color: transparent; 
  }
  
  .menu-item {
    color: white; 
    text-transform: uppercase; 
    font-size: 20px;
    margin: 0 60px; 
    z-index: 100;
  } */
  
  .menu-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 10vh; /* 10% dell'altezza dello schermo */
    color: white;
    text-transform: uppercase;
    font-size: 34px;
    z-index: 100;
    padding-left: 20px;
    padding-right: 20px;
    width: calc(100% - 40px);
    }
  
  .menu-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25%; /* Larghezza per le colonne esterne */
    text-decoration: underline;
    z-index: 100;

  }
  
  .menu-item:nth-child(2) {
    width: 50%; /* Larghezza per la colonna centrale */
  }
  

  @media (max-width: 768px) {


    .menu-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 10vh; /* 10% dell'altezza dello schermo */
      color: white;
      text-transform: uppercase;
      font-size: 40px;
      z-index: 100;
      padding-left: 20px;
      padding-right: 20px;
      width: calc(100% - 40px);
      }


  }