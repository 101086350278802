.modal-backdrop-on-people-screen{
  width: 100%;
  height: 0px;
  position: absolute;
  z-index: 23;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* padding-top: 56.25%; */
  height: 100vh;
  /* position: relative;*/
  }

 .modal-backdrop-on-people-screen video {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;

    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  } 

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(95px); /* Puoi aumentare il valore per una maggiore sfocatura */
    background-color: rgba(184, 155, 222, 0.778); /* Sfondo bianco con trasparenza */
    z-index: 24; /* Assicurati che sia sopra il video ma sotto il contenuto interattivo */
}



  .start-new-session-entered {
    opacity: 1;
    transition: opacity 3000ms ease-in;
    position: absolute;
    z-index: 101;
    width: 60%;
    height: 80px;
    background-color: var(--primary-color);
    border-radius: 25px;
    bottom: 15%;
    margin: 0 auto;
    color: white;
    text-transform: uppercase;
    font-size: 35px;
    line-height: 80px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .fade-in {
    animation: fadeIn 2s ease-out;
  }

  .modal-backdrop-on-people-screen svg{
    display: block;
  }

  .lottie_container{
    bottom: 10%;
    position: absolute;
    color: rgb(66, 177, 172);
    font-size: 35px;
    text-transform: uppercase;
    font-weight: bold;
  }